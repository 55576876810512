span {
  display: block;
}

.businessCard {
  position: relative;
  display: flex;
  width: 575px;
  max-width: 96%;
  padding: 2%;
}

.businessCard .front {
  display: flex;
  flex-grow: 1;
  backface-visibility: hidden;
}

.businessCard .back {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: white;
  width: 98%;
  margin-left: 1%;
  /*transform: rotateY(180deg) rotateX(-180deg);*/
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.businessCard .front,
.businessCard .back {
  /*transition: transform 1s linear;*/
  box-shadow: 0px 0px 15px -7px #999;
}

.businessCard:hover .front {
  animation: spinFront 1.5s linear;
}

.businessCard:hover .back {
  animation: spinBack 1.5s linear;
}

.businessCard > .stretcher {
  width: 0.0001%;
  padding-bottom: calc(100% * 55 / 85);
}

.businessCard .picture {
  display: flex;
  flex-direction: column;
  width: 39%;
  padding-right: 1%;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.picture img {
  width: 57%;
  border-radius: 50%;
}

.picture b {
  font-size: 100%; /*145px*/
  margin-top: 5%;
}

.picture span {
  font-size: 84%;
  margin-top: 1%;
  font-weight: 300;
}

.details {
  display: flex;
}

.businessCard .details {
  flex-grow: 1;
  background-color: rgb(87, 150, 116);
}

.icons {
  position: relative;
  margin: 22% 0 22% 7.5%;
  width: 14%;
  background-color: white;
  text-align: center;
}

.contactInfo {
  margin-left: 5%;
}

.contactInfo span {
  color: white;
}

.contactInfo a {
  color: white;
  text-decoration: none;
}

.contactInfo a:hover {
  text-decoration: underline;
}

.details .icons,
.details .contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details .icons span,
.details .contactInfo span {
  font-size: 110%;
  line-height: 300%;
}

.icons:after,
.icons:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  background-color: inherit;
  padding-bottom: 50%;
  width: 57.7%;
  z-index: 1;
  transform-origin: 0 0;
  transform: rotate(-30deg) skewX(30deg);
}

.icons:after {
  top: 100%;
}

.back img {
  width: 90%;
}

@keyframes spinFront {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

@keyframes spinBack {
  0% {
    transform: rotateY(180deg) rotateX(180deg);
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: rotateY(-180deg) rotateX(-180deg);
  }
}

@media screen and (max-width: 595px) {
  .picture b {
    font-size: 3vw; /*145px*/
  }

  .picture span {
    font-size: 2.5vw;
  }

  .details .icons span,
  .details .contactInfo span {
    font-size: 3vw;
  }
}
